//======================================
//  Colors
//======================================

// Neutrals
$white: #fff;
$smoke: #f8f8f8;
$gray-xlight: #eaeaea;
$gray-light: #bbb;
$gray: #505050;
$gray-dark: #231f20;
$black: #000;

// Primary
$green: #00b451;
$cyan: #007bbd;
$red: #bd3e00;

$link-color: $cyan;
$link-highlight: #dbf2ff;

//======================================
//    Fonts, weights
//======================================

$sans: 'PT Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
$rheiborn: 'rheiborn', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;

$normal: 400;
$bold: 700;

$base-font-size: 16px;


//======================================
//    Breakpoints
//======================================

$breakpoints: (
    'sm-mobile': 20em,        // 320px
    'mobile': 23.4375em,      // 375px
    'lg-mobile': 25em,        // 400px
    'phablet': 37.5em,        // 600px
    'sm-tablet': 46em,        // 736px
    'tablet-vert': 48em,      // 768px
    'lg-tablet': 50em,        // 800px
    'xs-desktop': 57.5em,     // 920px
    'sm-desktop': 62.5em,     // 1000px
    'tablet-horiz': 64em,     // 1024px
    'desktop': 75em,          // 1200px
    'md-desktop': 87.5em,     // 1400px
    'lg-desktop': 100em       // 1600px
);

$main-breakpoint: tablet-vert;          // Where the layout will all stack for mobile
$secondary-breakpoint: sm-desktop;      // Where narrow-width columns will stack before main breakpoint
