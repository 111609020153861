// //scss-lint:disable all
@import 'partials/variables';

body {
    background-color: $gray-dark;
    background-image: url('../images/bg/concrete-dark.jpg');
    background-repeat: repeat;
    font-family: $sans;
}

.login {
    position: relative;
    z-index: 2;

    h1 a {
        background-color: transparent;
        background-image: url('../images/logo/cbc-white.svg');
        background-position: center center;
        background-size: contain;
        border: none;
        height: 100px;
        padding: 24px;
        width: 270px;
    }

    #loginform {
        box-shadow: 0 0 2em rgba($black, 0.8);
        transition: box-shadow 0.3s ease;

        &:focus-within {
            box-shadow: 0 0 2em rgba($green, 0.8);
        }
    }

    form {
        border-radius: 0.25rem;
        position: relative;
    }

    #backtoblog,
    #nav {
        a {
            color: $white;

            &:hover,
            &:focus {
                color: $green;
            }
        }
    }

    #login_error,
    .message,
    .success {
        border-color: $cyan;
    }
}
